import { apiSlice } from "../utils/apiSlice";

const TAG_TYPE = 'authInfo';

export const authApiSlice = apiSlice.enhanceEndpoints({
    addTagTypes: [TAG_TYPE],
}).injectEndpoints({
    endpoints: (builder) => ({
        getUser: builder.query({
            query: (userId: string) => `/user/${userId}`
        }),
        login: builder.mutation({
            query: (body: {userName: string, password: string}) => {
                return {
                    url: '/user/login',
                    method: 'post',
                    body
                }
            }
        })
    })
})

export const {
    useGetUserQuery,
    useLoginMutation
} = authApiSlice;