import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IGridOptionsState } from "models/IGridOptionsState";

const initialState = {
    gridOptions: [] as IGridOptionsState[]
};
const PAGE_STATE = 'pageState';

const pageStateSlice = createSlice({
    name: PAGE_STATE,
    initialState,
    reducers: {
        setGridOptionsState: (state, action: PayloadAction<IGridOptionsState>) => {
            const gridOptionsState = action.payload;
            if (!gridOptionsState.name){
                throw new Error('setGridOptionsState cannot be used without name of the grid!');                
            }
            
            const idx = state.gridOptions.findIndex(x => x.name === gridOptionsState.name);
            if (idx === -1){
                state.gridOptions.push(gridOptionsState);
            } else {
                state.gridOptions[idx] = gridOptionsState;
            }

            sessionStorage.setItem('pageState.gridOptions', JSON.stringify(state.gridOptions));
        },
        restoreGridOptions: (state, action: PayloadAction) => {
            const pageStateData = sessionStorage.getItem('pageState.gridOptions');
            if (pageStateData){
                state.gridOptions = JSON.parse(pageStateData);
            }
        },
        clearGridOptions: (state, action: PayloadAction) => {
            state.gridOptions = [];
            sessionStorage.setItem('pageState.gridOptions', JSON.stringify([]));
        }
    }
});

export const getGridOptionsState = (state: RootState) => state.pagesStates.gridOptions;

export const {
    setGridOptionsState,
    restoreGridOptions,
    clearGridOptions
} = pageStateSlice.actions;

export default pageStateSlice.reducer;