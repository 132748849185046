import { FC } from "react";
import type { ApexOptions } from "apexcharts";
import {
  Avatar,
  Box,
  Theme,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import Text from "components/Text";
import Label from "components/Label";

import ReactApexChart from "react-apexcharts";

export const createApexOptions = (
  theme: Theme,
  labels: string[],
  colors: string[],
  tickAmount: number,
  titleFormater: (seriesName: string) => string
): ApexOptions => {
  const options: ApexOptions = {
    noData: {
      text: 'Loading...'
    },
    chart: {
      animations: {
        enabled: false,
      },
      background: "transparent",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    labels: labels,
    fill: {
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.1,
        inverseColors: false,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [0, 100],
      },
    },
    colors: colors,
    stroke: {
      curve: "smooth",
      width: 2,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      labels: {
        show: true, 
        hideOverlappingLabels: true, 
        rotateAlways: false
      },
      axisTicks: {
        show: true
      },
      tickPlacement: 'between',
      tickAmount: tickAmount
    },
    grid: {
      padding: {
        top: 10,
        right: 5,
        bottom: 50,
        left: 5,
      },
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      fixed: {
        enabled: true,
      },
      x: {
        show: true,
      },
      y: {
        title: {
          formatter: titleFormater,
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return options;
};

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      margin: ${theme.spacing(0, 0, 1, -0.5)};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacing(1)};
      padding: ${theme.spacing(0.5)};
      border-radius: 60px;
      height: ${theme.spacing(5.5)};
      width: ${theme.spacing(5.5)};
      background: ${
        theme.palette.mode === "dark"
          ? theme.colors.alpha.trueWhite[30]
          : alpha(theme.colors.alpha.black[100], 0.07)
      };
    
      img {
        background: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0.5)};
        display: block;
        border-radius: inherit;
        height: ${theme.spacing(4.5)};
        width: ${theme.spacing(4.5)};
      }
  `
);

interface ChartStatisticProps {
  icon?: JSX.Element;
  title: string;
  subtitle?: string;
  period?: string;
  totalText?: string;
  totalSubText?: string;
  options: ApexOptions;
  data: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
}

const ChartStatistic: FC<ChartStatisticProps> = ({
  icon,
  title,
  subtitle,
  period,
  totalText,
  totalSubText,
  options,
  data,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        p: 3,
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          {icon && <AvatarWrapper>{icon}</AvatarWrapper>}
          <Box>
            <Typography variant="h4" noWrap>
              {title}
            </Typography>
            <Typography variant="subtitle1" noWrap>
              {subtitle}
            </Typography>
          </Box>
        </Box>
        {period && <Label color="secondary">{period}</Label>}
      </Box>
      {totalText && (
        <Box
          mt={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                pr: 1,
              }}
            >
              {totalText}
            </Typography>
            {totalSubText && (
              <Text color="success">
                <b>+12.5%</b>
              </Text>
            )}
          </Box>
        </Box>
      )}
      <Box pt={2}>
        <ReactApexChart
          options={options}
          series={data}
          type="line"
          height={200}
        />
      </Box>
    </Box>
  );
};

export default ChartStatistic;
