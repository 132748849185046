import { FC } from "react";
import { ILogView } from "./ILogView";
import { Column } from "material-table";
import { IGridOptions, onRowStylingType } from "utils/gridUtils";
import DataGrid from "components/DataGrid";
import ReactJson from "@microlink/react-json-view";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

interface LogsDataGridProps {
    name: string,
    gridOptions: IGridOptions,
    refreshCounter: any
    onFetchData: any,
    isFetching: boolean,
    isError: boolean,
    error: FetchBaseQueryError | SerializedError | undefined,
    highlightErrors?: boolean,
    showAppColumn?: boolean,
    showLevelColumn?: boolean
}

const LogsDataGrid: FC<LogsDataGridProps> = ({name, onFetchData, isFetching, isError, error, gridOptions, refreshCounter, highlightErrors, showAppColumn, showLevelColumn}) => {
    const allColumns: (Column<ILogView> | undefined)[] = [
        { field: 't', title: 'Date', type: 'datetime', width: "20%"},
        (showLevelColumn ? { field: 'l', title: 'Level', type: 'string', sorting: false} : undefined),
        (showAppColumn ? { field: 'a', title: 'App', type: 'string', sorting: false} : undefined),
        { field: 'm', title: 'Message', type: 'string', sorting: false, render: (data, type) => <>
            {data.m}
            <div style={{'fontWeight': 'bold'}}>{data.x}</div><br/>
            <ReactJson 
                src={data.p}
                displayObjectSize={false}
                displayDataTypes={false}
                name="props"
                shouldCollapse={f => f.name != 'props'} />
        </>}
    ];

    const columns = allColumns.filter(c => !!c) as Column<ILogView>[];

    let onRowStyling: (onRowStylingType | undefined) = undefined; 
    if (highlightErrors){
        onRowStyling = (data, index, level, rowStyling) => {
            if (data.l === 'ERR') {
                rowStyling['color'] = 'red';
            }
            return rowStyling; 
        };
    }
    
    return <DataGrid 
        name={name}
        filtering={false}
        gridOptions={gridOptions}
        onFetchData={onFetchData}
        isError={isError}
        error={error}
        isLoading={isFetching}
        columns={columns}
        page={gridOptions.page}
        actions={[]}
        refreshCounter={refreshCounter}
        onRowStyling={onRowStyling}
    />
};

export default LogsDataGrid;