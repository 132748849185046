import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import Overview from "./content/overview";
import BaseLayout from "./layouts/BaseLayout";
import { Suspense, lazy, Component, PropsWithChildren } from 'react';
import SuspenseLoader from './components/SuspenseLoader';
import SidebarLayout from './layouts/SidebarLayout';
import Login from './content/auth/Login';
import Footer from 'components/Footer';
import { Container } from '@mui/material';
import Status500 from 'components/StatusPage/Status500';

const Loader = (Component: any) => (props: PropsWithChildren) => (
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
)

const Orders = Loader(lazy(() => import('./content/applications/Orders/components')));
const Settings = Loader(lazy(() => import('./content/applications/Settings/components/items')));
const Centers = Loader(lazy(() => import('./content/applications/Centers/components')));
const Statuses = Loader(lazy(() => import('./content/applications/Statuses/components')));
const Logs = Loader(lazy(() => import('./content/applications/Logs/components')));

const NotFound = (<>
    <Container maxWidth="lg" sx={{marginTop: '30px'}}>
        <Status500 />
    </Container>
    
    <Footer />
</>)

const GeneralError = (<>
    <Container maxWidth="lg" sx={{marginTop: '30px'}}>
        <Status500 />
    </Container>
    
    <Footer />
</>);

const wrapErrors = (routes: RouteObject[]) => {
    return [{
        path: '500',
        element: GeneralError
    },
    ... routes,
    {
        path: '*',
        element: NotFound
    }]
}

export const nonAuthRoutes: RouteObject[] = [
    {
        path: '',
        element: <BaseLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },{
                path: '/',
                element: <Navigate to="/login" replace />
            },{
                path: '*',
                element: <Navigate to="/login" replace />
            }
        ]
    }
];

export const routes: RouteObject[] = [
    {
        path: '',
        element: <BaseLayout />,
        children: [
            {
                path: '/',
                element: <SidebarLayout />,
                children: [
                    {
                        path: '/',
                        element: <Overview />
                    }
                ]
            },{
                path: 'login',
                element: <Navigate to="/" replace />
            },{
                path: 'overview',
                element: <Navigate to="/" replace />
            },{
                path: 'orders/*',
                element: <SidebarLayout />,
                children: wrapErrors([
                    {
                        path: '*',
                        element: <Orders />
                    }
                ])
            },{
                path: 'settings/*',
                element: <SidebarLayout />,
                children: wrapErrors([
                    {
                        path: '*',
                        element: <Settings />
                    }
                ])
            },{
                path: 'centers/*',
                element: <SidebarLayout />,
                children: wrapErrors([
                    {
                        path: '*',
                        element: <Centers />
                    }
                ])
            },{
                path: 'statuses/*',
                element: <SidebarLayout />,
                children: wrapErrors([
                    {
                        path: '*',
                        element: <Statuses />
                    }
                ])
            },{
                path: 'logs/*',
                element: <SidebarLayout />,
                children: wrapErrors([
                    {
                        path: '*',
                        element: <Logs />
                    }
                ])
            },{
                path: "*",
                element: <SidebarLayout />,
                children: wrapErrors([])                
            }
        ]
    }
];