import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "utils/apiSlice";
import authInfoReducer from "reducers/authSlice";
import pageStateReducer from "reducers/pageStateSlice";

const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        authInfo: authInfoReducer,
        pagesStates: pageStateReducer
    },
    devTools: true,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(apiSlice.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;