import { Theme } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "hooks";
import { Column, Options } from "material-table";
import { getGridOptionsState, setGridOptionsState } from "reducers/pageStateSlice";

export type IOrderDirection = "asc" | "desc";

export interface IFilter {
    field: string,
    value: any
}

export interface IPageDataRequest {
    skip?: number,
    take?: number,
    orderBy?: string,
    orderDirection?: IOrderDirection
    filters?: IFilter[]
}

export interface IPageDataResponse<T extends object> {
    data: T[],
    count: number
}

export const createEmptyPageDataResponse: <T extends object>() => IPageDataResponse<T> = () => ({
    data: [],
    count: 0
})

export interface IGridOptions {
    page?: number,
    pageSize?: number,
    orderBy?: string,
    orderDirection?: IOrderDirection,
    filters?: IFilter[]
}

export const useGridOptions = (
    pageName: string,
    gridOptions: IGridOptions
) => {
    const dispath = useAppDispatch();
    const gridOptionsState = useAppSelector(getGridOptionsState);
    let state = gridOptionsState?.find(p => p.name === pageName)?.state;
    if (!state) {
        state = gridOptions;
    }

    return {
        gridOptions: state,
        setGridOptions: (gridOptions: IGridOptions) => dispath(setGridOptionsState({
            name: pageName,
            state: gridOptions
        }))
    }
}

export const applyGridOptionsToColumns = <T extends object>(gridOptions: IGridOptions, columns: Column<T>[]) => {
    if (!gridOptions || !columns){
        return columns;
    }

    columns = columns.map(c => ({
        ...c, 
        defaultSort: undefined, 
        hideFilterIcon: true,
        customFilterAndSearch: () => true} as Column<T>) );

    if (gridOptions.orderBy) {
        columns = updateColumnsOrder(columns, gridOptions.orderBy, gridOptions.orderDirection);
    }

    for (var column of columns) {
        const filter = gridOptions.filters?.find(f => f.field === column.field);
        if (filter) {
            if (column.type === 'boolean') {
                column.defaultFilter = filter.value === true || filter.value === 'true' ? 'checked' : 'unchecked';
            } else if (column.type === 'date' || column.type === 'datetime') {
                column.defaultFilter = typeof filter.value === 'string'
                    ? new Date(Date.parse(filter.value))
                    : filter.value;
            } else {
                column.defaultFilter = filter.value;
            }
        } else {
            column.defaultFilter = undefined;
        }
    }

    return columns;
}

export const columnBorderStyle = 'solid 1px rgb(213, 213, 251, .3)';
export const createColumnBorder = <T extends {}>(isLeft: boolean, column: Column<T>) => {
    const s = isLeft ? {borderLeft: columnBorderStyle} : {borderRight: columnBorderStyle}
    column.cellStyle = s;
    column.headerStyle = s;
    column.filterCellStyle = s;
    return column;
}

export const createGridOptions = (
    orderBy?: string,
    orderDirection?: IOrderDirection,
    page?: number,
    pageSize?: number,
    filters?: IFilter[]
) => {
    return {
        page,
        pageSize: pageSize || defaultPageSize,
        orderBy,
        orderDirection,
        filters
    } as IGridOptions;
}

export const defaultPageSize = 10;
export const maxPageSize = 50;

export const toPageDataRequest: (options: IGridOptions) => IPageDataRequest = ({page, pageSize: limit, orderBy, orderDirection, filters}) => {
    const take = (limit || 0) > 0 ? limit : defaultPageSize;
    const skip = (page || 0) > 0 ? page! * take! : 0;
    return ({
        skip: skip,
        take: take,
        orderBy: orderBy,
        orderDirection: orderDirection as IOrderDirection,
        filters
    });
}

export type onRowStylingType = (data: any, index: number, level: number, rowStyling: React.CSSProperties) => React.CSSProperties;
export type getGridDefaultOptionsType = <T extends object>(theme?: Theme, filtering?: boolean, pageSize?: number, onRowStyling?: onRowStylingType) => Options<T>;
export const getGridDefaultOptions: getGridDefaultOptionsType = (theme, filtering, pageSize, onRowStyling) => ({
    sorting: true,
    filtering: filtering,
    debounceInterval: 500,
    draggable: false,
    pageSize: pageSize || defaultPageSize,
    pageSizeOptions: [defaultPageSize, 20, 50], 
    search: false,
    showTitle: false, 
    emptyRowsWhenPaging: false, 
    filterRowStyle: {
        backgroundColor: 'rgba(85, 105, 255, .1)'
    },
    rowStyle: (row: any, index: number, level: number) => {
        let rowStyling: React.CSSProperties = {
            backgroundColor: 'inherit'
        };

        if (theme) {
            if (index % 2) {
                rowStyling.backgroundColor = theme?.general.backgroundColor!;
            }
        }

        if (onRowStyling){
            rowStyling = onRowStyling(row, index, level, rowStyling);
        }
        return rowStyling;
    }
});

export function updateColumnsOrder<T extends object>(columns: Column<T>[], orderBy?: string, orderDirection?: IOrderDirection) {
    if (!columns) {
        return columns;
    }

    return columns.map((c) => (c.field !== orderBy ? {...c, defaultSort: null} : {...c, defaultSort: orderDirection})) as Column<T>[];
}