import { FC, useEffect, useState } from "react";
import { useLoginMutation } from "../../api/authApiSlice";
import { useAppDispatch } from "../../hooks";
import { setAuthInfo } from "reducers/authSlice";
import { useNavigate } from "react-router";
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';

import {
    Box,
    Card,
    Typography,
    Container,
    Button,
    FormControl,
    TextField
} from '@mui/material';
import Text from '../../components/Text';
import { Helmet } from 'react-helmet-async';

import { styled } from '@mui/material/styles';
import { useSearchParams } from "react-router-dom";
import { sessionTimeoutParam } from "../../utils/config";
import { LoadingButton } from "@mui/lab";
import { clearGridOptions } from "reducers/pageStateSlice";

const MainContent = styled(Box)(
    ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const Login: FC = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [login, {data: authInfo, isSuccess, isError, isLoading, error}] = useLoginMutation();
    const dispath = useAppDispatch();
    const [loginAttempts, setLoginAttempts] = useState(0);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const welcomeMessage = searchParams.has(sessionTimeoutParam) && !loginAttempts
        ? 'Your session expired. Please provide your credentials to sign in.'
        : 'Welcome to dashboard. Please provide your credentials to sign in.';

    useEffect(() => {
        if (isSuccess) {
            dispath(setAuthInfo(authInfo));
            navigate('/orders');
        } else if (error) {
            const status = (error as any).status;

            if (status === 401) {
                setErrorMessage('Failed to sign in. Please provide valid credentials.')
            } else {
                setErrorMessage('Fatal error. Please contact system administartor.')
            }
        }
    }, [isSuccess, error]);

    const onLogin = function() {
        setLoginAttempts((prev: number) => prev + 1);
        setErrorMessage('');

        if (userName && password) {
            login({
                userName: userName, 
                password: password
            }).then(() => {
                dispath(clearGridOptions());
            });
        }
    }

    return (
        <>
            <Helmet>
                <title>Status - 404</title>
            </Helmet>
            <MainContent>
                <Container maxWidth="md">
                    <Box textAlign="center">
                        <Typography variant="h2" sx={{ my: 2 }}>
                            Presserro / CoreBridge
                        </Typography>
                        <Typography
                            variant="h4"
                            color="text.secondary"
                            fontWeight="normal"
                            sx={{ mb: 4 }}
                        >
                            {welcomeMessage}
                        </Typography>
                    </Box>
                    <Container maxWidth="sm">
                        <Card sx={{ mt: 3, p: 4 }}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    error={loginAttempts > 0 && !userName}
                                    id="username"
                                    required
                                    label="User name"
                                    placeholder={loginAttempts > 0 ? "User name is required" : "User name"}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                                <TextField
                                    error={loginAttempts > 0 && !password}
                                    type="password"
                                    id="password"
                                    required
                                    label="Password"
                                    placeholder={loginAttempts > 0 ? "Password is required" : "Password"}
                                    sx={{ my: 2 }}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormControl>
                            {errorMessage && <Text color="error" sx={{my: 1}}>{errorMessage}</Text>}
                            <LoadingButton
                                loading={isLoading}
                                variant="contained" 
                                sx={{float: 'right'}} 
                                onClick={onLogin} 
                                startIcon={<LockOpenTwoToneIcon />}
                                disabled={isLoading}>
                                Sign in
                            </LoadingButton>
                        </Card>
                    </Container>
                </Container>
            </MainContent>
        </>
    );

}

export default Login;