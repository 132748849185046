import { apiSlice } from "../../../utils/apiSlice";
import { IStatisticView } from "../models/IStatisticView";
import { IStatisticDateRangeRequest } from "../models/IStatisticDateRangeRequest";
import { IPageDataResponse, toPageDataRequest } from "utils/gridUtils";
import { ILogView } from "content/applications/Logs/models/ILogView";
import { ILogsOptions } from "content/applications/Logs/models/ILogsOptions";

const TAG_TYPE = 'Statistics';

export const StatisticApiSlice = apiSlice.enhanceEndpoints({
    addTagTypes: [TAG_TYPE],
}).injectEndpoints({
    endpoints: (builder) => ({
        getStatisticsDateRange: builder.query<IStatisticView[], IStatisticDateRangeRequest>({
            query: (options) => ({
                method: 'POST',
                url: `/statistics/range`,
                cache: 'no-cache',
                body: options
            }),
            keepUnusedDataFor: 60
        }),
        getErrors: builder.query<IPageDataResponse<ILogView>, ILogsOptions>({
            query: (options) => ({
                method: 'POST',
                url: `/log/items?fileName=Erorrs.log`,
                cache: 'no-cache',
                body: toPageDataRequest(options)
            }),
            keepUnusedDataFor: 0.0001
        })
    })
})

export const {
    useLazyGetStatisticsDateRangeQuery,
    useLazyGetErrorsQuery
} = StatisticApiSlice;