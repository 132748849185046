export const stringToColor = (string: string) => {
    if (!string) {
        return '#ccc';
    }
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export const stringAvatar = (name: string) => {
    const names = name?.split(' ') || ['U'];
    return {
        sx: {
            bgcolor: stringToColor(name)
        },
        children: `${names[0][0]}${(names.length > 1 ? names[1][0] : '')}`
    };
} 