import { useContext } from "react";

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Button,
  Typography,
  Avatar
} from "@mui/material";
import MenuTwoToneIcon from "@material-ui/icons/MenuTwoTone";
import { SidebarContext } from "../../../contexts/SidebarContext";
import CloseTwoToneIcon from "@material-ui/icons/CloseTwoTone";
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getAuthInfo, setAuthInfo } from "reducers/authSlice";
import { useNavigate } from "react-router";
import { stringAvatar } from "../../../utils/stringUtils";

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background as string, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        flex-grow: 1;
        text-align: left;
        padding-left: ${theme.spacing(1)};
        align-self: left;
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        font-size: ${theme.typography.pxToRem(20)};
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const authInfo = useAppSelector(getAuthInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const onSignOut = () => {
    dispatch(setAuthInfo({}));
    navigate('/');
  }

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === "dark"
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.black[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`,
      }}
    >
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        spacing={2}
      >
        <Avatar {...stringAvatar(authInfo.userName!)} /> 
        <UserBoxText>
          <UserBoxLabel variant="body1">          
            {'Hello, ' +  (authInfo.userName || 'Anonymous') + ' !'}
          </UserBoxLabel>
        </UserBoxText>
      </Stack>
      <Stack direction="row" alignItems="center" sx={{flexGrow: 1}}>
      </Stack>
      <Button color="primary" onClick={onSignOut}>
          <LockTwoToneIcon style={{ marginRight: 1 }} />
          Sign out
      </Button>
      <Box display="flex" alignItems="center">        
        <Box
          component="span"
          sx={{
            ml: 2,
            display: { lg: "none", xs: "inline-block" },
          }}
        >
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? (
                <MenuTwoToneIcon fontSize="small" />
              ) : (
                <CloseTwoToneIcon fontSize="small" />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
