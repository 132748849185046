import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: IAuthInfo = {}
const AUTH_INFO = 'authInfo';

const authSlice = createSlice({
    name: AUTH_INFO,
    initialState,
    reducers: {
        setAuthInfo: (state, action: PayloadAction<IAuthInfo>) => {
            localStorage.setItem(AUTH_INFO, JSON.stringify(action.payload));
            state.userName = action.payload.userName;
            state.token = action.payload.token;
        }
    }
});

export const getAuthInfo = (state: RootState) => state.authInfo;

export const {
    setAuthInfo
} = authSlice.actions;

export default authSlice.reducer;