import { FC } from "react";
import { Box, Typography, Container, Grid, Button } from "@mui/material";

import { Helmet } from "react-helmet-async";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 0;
`
);

const Status500: FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Status - 500</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Box textAlign="center">
            <img alt="500" height={260} src="/static/images/status/500.svg" />
            <Typography variant="h2" sx={{ my: 2 }}>
              There was an error, please try again later
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{ mb: 4 }}
            >
              The server encountered an internal error and was not able to
              complete your request
            </Typography>
            <Button
              variant="contained"
              sx={{ ml: 1 }}
              onClick={() => navigate(-1)}
            >
              Try again
            </Button>
          </Box>
        </Container>
      </MainContent>
    </>
  );
};

export default Status500;
