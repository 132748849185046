import { Typography, Grid, Button } from '@mui/material';
import RefreshContentButton from 'components/RefreshContentButton';

function PageHeader() {
 
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Dashboard
        </Typography>
        <Typography variant="subtitle2">
          Presserro Centers and Core Bridge integration dashboard
        </Typography>
      </Grid>
      <RefreshContentButton />
    </Grid>
  );
}

export default PageHeader;
