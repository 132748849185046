import { Card, CardContent, CardHeader, Divider } from "@mui/material";
import { FC, useState } from "react";
import { createGridOptions, maxPageSize, useGridOptions } from "utils/gridUtils";
import LogsDataGrid from "components/LogsDataGrid";
import { useLazyGetErrorsQuery } from "./api/statisticApiSlice";

const ErrorItems: FC = () => {
    const defGridOptions = createGridOptions("t", "desc", 0, 10);

    const { gridOptions, setGridOptions } = useGridOptions("Errors", defGridOptions);
    const [refreshCounter, setRefreshCounter] = useState(Date.now());

    const [fetchData, {isFetching, isError, error}] = useLazyGetErrorsQuery();

    return (
        <Card>
            <CardHeader title="Errors" />
            <Divider/>
            <CardContent>
                <LogsDataGrid 
                    name="Errors"
                    gridOptions={gridOptions}
                    refreshCounter={refreshCounter}
                    onFetchData={fetchData}
                    isFetching={isFetching}
                    isError={isError}
                    error={error}
                    showAppColumn={true}                    
                />
            </CardContent>
        </Card>
    );
};

export default ErrorItems;