import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import MaterialTable, { Column, Filter, MaterialTableProps } from "material-table";
import { tableIcons } from "components/TableIcons";
import { IGridOptions, IOrderDirection, IPageDataResponse, applyGridOptionsToColumns, createEmptyPageDataResponse, getGridDefaultOptions, onRowStylingType, updateColumnsOrder, useGridOptions } from "utils/gridUtils";
import { useTheme } from "@mui/material/styles";
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, QueryActionCreatorResult, QueryDefinition } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { useApiError } from "utils/apiSlice";
import { RefreshContext } from "contexts/RefreshContext";

export type DataGridProps<RowData extends object> = Omit<MaterialTableProps<RowData>, "data"> & {
    name: string,
    filtering?: boolean,
    gridOptions: IGridOptions,
    onFetchData: (gridOptions: IGridOptions, preferCacheValue?: boolean) 
        => QueryActionCreatorResult<QueryDefinition<IGridOptions, BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>, any, IPageDataResponse<RowData>, "rtk">>    
    isError: boolean, 
    error?: FetchBaseQueryError | SerializedError,
    refreshCounter?: any,
    onRowStyling?: onRowStylingType,
}

const DataGrid: FC<DataGridProps<any>> = <RowData extends object>(props: DataGridProps<RowData>) => {
    const {onContentRefresh} = useContext(RefreshContext);
    const { processError } = useApiError();
    const { name, filtering, gridOptions, onFetchData: fetchData, isError, error, refreshCounter, columns, onRowStyling, ...rest } = props;
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const [pagedData, setPagedData] = useState(createEmptyPageDataResponse<RowData>());

    const { gridOptions: options, setGridOptions: setOptions } = useGridOptions(name, gridOptions);

    const [gridColumns, setGridColumns] = useState<Column<RowData>[]>(applyGridOptionsToColumns(options, columns));

    const loadData = (options: IGridOptions) => fetchData(options, false)
        .then(({data, isError, error}) => {

            if (!isError && data) {
                setPagedData({
                    data: data.data.map(x => ({...x})),
                    count: data.count
                });
            }
        });

    useEffect(() => {
        const onContentRefreshCallback = () => {
            loadData(options);
        };
        
        onContentRefresh(onContentRefreshCallback);
        return () => {
            onContentRefresh(onContentRefreshCallback, true);
        }
    }, [options]);
    

    useEffect(() => {
        loadData(options);
    }, [refreshCounter]);

    useEffect(() => {
        if (isError) {
            if (!processError(error)) {
                enqueueSnackbar('Failed to load data', {variant: 'error'});
            }
        }
    }, [rest.isLoading]);

    const onOrderChanging = (orderByNum: number, orderDirection: IOrderDirection) => {
        const orderBy = orderByNum !== -1 ? gridColumns[orderByNum].field : undefined;
        const newGridOptions = {
            ...options,
            orderBy,
            orderDirection
        } as IGridOptions;
        setOptions(newGridOptions);
        loadData(newGridOptions);
        setGridColumns(updateColumnsOrder(gridColumns, orderBy as string, orderDirection));
    }

    const onPageChanging = (page: number, pageSize: number) => {
        const newGridOptions = {
            ...options,
            page,
            pageSize
        } as IGridOptions;
        setOptions(newGridOptions);
        loadData(newGridOptions);
    }

    const onChangeRowsPerPage = (pageSize: number) => {
        const newGridOptions = {
            ...options,
            page: 0,
            pageSize
        } as IGridOptions;
        setOptions(newGridOptions);
        loadData(newGridOptions);
    }

    const onFiltering = (filters: Filter<RowData>[]) => {
        const newGridOptions = {
            ...options,
            page: 0,
            filters: filters.map(f => ({
                field: f.column.field,
                value: f.column.type !== 'boolean'
                    ? f.value
                    : (f.value === 'checked' 
                        ? 'true' : (f.value === 'unchecked' 
                            ? 'false' 
                            : ''))
            }))
        } as IGridOptions;
        setOptions(newGridOptions);
        loadData(newGridOptions);
    }

    return (
        <MaterialTable 
            {...rest}
            icons={tableIcons}
            columns={gridColumns}
            page={options.page}
            data={pagedData.data}
            totalCount={pagedData.count}
            options={getGridDefaultOptions<RowData>(theme, filtering, gridOptions.pageSize, onRowStyling)}
            onOrderChange={onOrderChanging}
            onChangePage={onPageChanging}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onFilterChange={onFiltering}
            localization={{header: {actions: ''}}}
        />
    );
}

export default DataGrid;