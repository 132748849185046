import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from "react-redux";
import store from './store'

import App from "./App";
import { SidebarProvider } from "./contexts/SidebarContext";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

let chain = Promise.resolve();

chain.then(() => {
  root.render(
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter >
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  );
});
