import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import PageTitleWrapper from "components/PageTitleWrapper";
import {
  Card,
  Container,
  Grid,
  Theme,
  useTheme,
} from "@mui/material";
import PageHeader from "./PageHeader";
import Footer from "components/Footer";
import ChartStatistic, { createApexOptions } from "./chartStatistic";
import TrendingUpTwoToneIcon from "@material-ui/icons/CloudUploadTwoTone";
import ImportContactsTwoToneIcon from "@material-ui/icons/CloudDownloadTwoTone";
import { useLazyGetStatisticsDateRangeQuery } from "./api/statisticApiSlice";
import { ApexOptions } from "apexcharts";
import { RefreshContext } from "contexts/RefreshContext";
import { formatTime } from "utils/dateUtils";
import { useApiError } from "utils/apiSlice";
import { useSnackbar } from "notistack";
import ErrorItems from "./ErrorItems";

const createDateRange = (startDate: Date, endDate: Date): Date[] => {
  let onDate: Date = new Date(startDate);
  const res: Date[] = [];
  while (onDate <= endDate) {
    res.push(onDate);
    onDate = new Date(onDate);
    onDate.setMinutes(onDate.getMinutes() + 1);
  }
  return res;
}

const createDateApexOptions = (dates: Date[], theme: Theme) => {
  return createApexOptions(
    theme,
    dates.map(x => formatTime(x)),
    [theme.colors.success.main, theme.colors.error.main],
    4, // each hour
    (seriesName: string) => {
      return `${seriesName}: `;
    }
  );
}

const ITEM_REGISTER_SUCCEEDED = 'Worker.ImportMissingPsoOrders.ItemRegisterSucceeded'
const TRANSMIT_FAILED = 'Worker.ProcessRegisteredOrders.TransmitFailed';
const TRANSMIT_SUCCEEDED = 'Worker.ProcessRegisteredOrders.TransmitSucceeded';

const getFilterDates = () => {
  const endDate = new Date(Date.now());
  endDate.setSeconds(0, 0);
  const startDate = new Date(Date.now());
  startDate.setHours(endDate.getHours() - 4);
  startDate.setMinutes(endDate.getMinutes(), 0, 0);
  return {
    startDate,
    endDate
  };
}

const createDefaultContext = (startDate: Date, endDate: Date, theme: Theme): IStatisticsContext => {
  const names = [ITEM_REGISTER_SUCCEEDED, TRANSMIT_FAILED, TRANSMIT_SUCCEEDED];

  const dates = createDateRange(startDate, endDate);

  return {
    names: names,
    startDate: startDate,
    endDate: endDate,
    dates: dates,
    options: createDateApexOptions(dates, theme),
    importData: [],
    transferData: []
  }
}

interface IStatisticsContext {
  names: string[]
  startDate: Date,
  endDate: Date,
  dates: Date[],
  options: ApexOptions,
  transferData: ApexAxisChartSeries,
  importData: ApexAxisChartSeries
}

function Overview() {
  const theme = useTheme();
  const {onContentRefresh} = useContext(RefreshContext);
  const { processError } = useApiError();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [fetchData, {data, isFetching, isError, error}] = useLazyGetStatisticsDateRangeQuery();
  const {startDate, endDate} = getFilterDates();
  const [context, setContext] = useState<IStatisticsContext>(createDefaultContext(startDate, endDate, theme));

  const onLoadData = (forceLoad?: boolean) => {
    const {startDate, endDate} = getFilterDates();
    
    if (forceLoad || context.startDate.getTime() != startDate.getTime()) {
      fetchData({
        startDate: startDate,
        endDate: endDate,
        names: context.names
      });
    }
  }

  useEffect(() => {
    if (isError){
      if (!processError(error)) {
        closeSnackbar();
        enqueueSnackbar('Failed to load data', {variant: 'error'});
      }            
    }
    else if (!isFetching && data) {
        var newContext = createDefaultContext(startDate, endDate, theme);

        const toMap = (name: string) => new Map<number, number>(data.filter(x => x.name === name).map(x => [Date.parse(x.onDate), parseInt(x.value)]));
        const toData = (items: Map<number, number>) => newContext.dates.map(onDate => items.get(onDate.getTime()) || 0);
        const itemRegisterSucceeded = toMap(ITEM_REGISTER_SUCCEEDED);
        const transmitFailed = toMap(TRANSMIT_FAILED);
        const transmitSucceeded = toMap(TRANSMIT_SUCCEEDED);

        newContext.importData = [{
          name: "Imported",
          data: toData(itemRegisterSucceeded)
        }];

        newContext.transferData = [{
          name: "Transferred",
          data: toData(transmitSucceeded),
          zIndex: 10
        },{
          name: "Failed",
          data: toData(transmitFailed)
        }];

        setContext(newContext);
    }

    onContentRefresh(onLoadData);
    return () => {
        onContentRefresh(onLoadData, true);
    }
  }, [isFetching]);

  useEffect(() => {
    onLoadData(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Card
              sx={{
                overflow: "visible",
              }}
            >
              <ChartStatistic
                icon={
                  <ImportContactsTwoToneIcon
                    style={{
                      color: `${theme.colors.success.main}`,
                    }}
                  />
                }
                title="Import"
                subtitle="Import of missing Presserro orders"
                period="4h"
                options={context.options}
                data={context.importData}
              />
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card
              sx={{
                overflow: "visible",
              }}
            >
              <ChartStatistic
                icon={
                  <TrendingUpTwoToneIcon
                    style={{
                      color: `${theme.colors.success.main}`,
                    }}
                  />
                }
                title="Export"
                subtitle="Export Presserro orders to Core Bridge"
                period="4h"
                options={context.options}
                data={context.transferData}
              />
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <ErrorItems />
          </Grid>

        </Grid>
      </Container>
      <Footer />
    </>
  );
}

export default Overview;
