import { RefreshContext } from "contexts/RefreshContext";
import { FC, useContext, useEffect, useState } from "react";
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import { Grid, Button } from '@mui/material';

const RefreshContentButton: FC = () => {
    const { hasContentToRefresh, onHasContentToRefreshChanged, refreshContent } = useContext(RefreshContext);
    const [canRefresh, setCanRefresh] = useState(hasContentToRefresh);

    useEffect(() => {
        onHasContentToRefreshChanged(value => {
            //console.log('Someone just subscribed for refreshes');
            setCanRefresh(value);
        });
    }, []);
  
    return canRefresh && (<Grid item>
        <Button
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="contained"
            startIcon={<RefreshOutlined fontSize="small"/>}
            onClick={refreshContent}
        >
            Refresh
        </Button>
    </Grid>);
}

export default RefreshContentButton;