import React from 'react';
import { PropsWithChildren } from 'react';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { themeCreator } from "./base";

const ThemeProviderWrapper = ({children}: PropsWithChildren) => {
    const theme = themeCreator("PureLightTheme");
    return <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
    </ThemeProvider>
}

export default ThemeProviderWrapper;