import React from 'react';
import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import { Outlet } from 'react-router-dom';

const BaseLayout: React.FC = (props: PropsWithChildren) => {
    return (
        <Box
            sx={{flex: 1, height: '100%'}}>
            {props.children || <Outlet />}
        </Box>);
}

export default BaseLayout;