import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC } from 'react';
import IconClose from '@material-ui/icons/Close';

interface SnackbarCloseButton {
    snackbarKey: SnackbarKey
}

const SnackbarCloseButton: FC<SnackbarCloseButton> = ({ snackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose style={{color: '#fff'}} />
    </IconButton>
  );
}

export default SnackbarCloseButton;