import { format } from "date-fns";

export const formatDate = (date: Date) => format(typeof date == 'string' ? new Date(date) : date, 'dd MMMM yyyy');
export const formatGridDate = (date: Date) => format(typeof date == 'string' ? new Date(date) : date, 'yyyy-MM-dd');
export const formatTime = (date: Date, withSeconds?: boolean) => format(typeof date == 'string' ? new Date(date) : date, withSeconds ? 'HH:mm:ss' : 'HH:mm');

export const getUtc = (date: Date) => {
    const utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(utc);
}

export const getUtcNow = () => getUtc(new Date());

export const fromUtcString = (value: string) => {
    const d = new Date(Date.parse(value));
    return getUtc(d);
}