import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'store';
import config, { loginUrlPart, sessionTimeoutParam } from 'utils/config';
import { setAuthInfo } from 'reducers/authSlice';
import { useNavigate } from 'react-router';

const baseQuery = fetchBaseQuery(
{
    baseUrl: config?.gatewayUrl || '/api',
    credentials: 'same-origin',
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).authInfo?.token;
        token && headers.set('Authorization', `Bearer ${token}`);
        return headers;
    },
});

const ensureAuthenticatedFetchBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const status = result?.error?.status;

    if (status === 401 && !window.location.pathname.startsWith(loginUrlPart)) {
        api.dispatch(setAuthInfo({}));
        window.history.pushState('Login', 'Login', `${loginUrlPart}?${sessionTimeoutParam}`);
    }
    return result;
};

export const useApiError = () => {
    const navigate = useNavigate();

    return {
        processError: (error: any) => {
            console.error(error);

            let status: number | undefined;
            if (error && 'originalStatus' in error) {
                status = error.originalStatus;
            } else if (error && 'status' in error) {
                status = error.status;
            } 

            if (!status) {
                return false;
            }

            if (status !== 200) {
                navigate(status.toString());
                return true;
            } 

            return false;
        }
    }
}

export const apiSlice = createApi({
    reducerPath: 'rtk',
    baseQuery: ensureAuthenticatedFetchBaseQuery,
    endpoints: (builder) => ({})
})
