import { FC, PropsWithChildren, createContext, useEffect, useLayoutEffect, useRef } from "react"
import { useLocation } from "react-router";

export type RefreshCallbackType = (() => any) | undefined;
export type CanRefreshCallbackType = ((canRefresh: boolean) => any) | undefined;
type RefreshContext = {
    refreshContent: () => void,
    hasContentToRefresh: boolean,
    onHasContentToRefreshChanged: (callback: CanRefreshCallbackType, remove?: boolean) => void,
    onContentRefresh: (callback: RefreshCallbackType, remove?: boolean) => void
}

export const RefreshContext = createContext<RefreshContext>({} as RefreshContext);

export const RefreshProvider: FC<PropsWithChildren> = ({children}) => {
    const onRefresh = useRef<RefreshCallbackType[]>([]);
    const onCanRefreshChanged = useRef<CanRefreshCallbackType[]>([]);

    // CANNOT GARANTEE THE ORDER OF USEEFFECTS SO LOCATION CAHNGE CANNOT BE USED
    // TO RESET unload ation should be used

    // const location = useLocation();
    // useLayoutEffect(() => {
    //     onRefresh.current = [];
    //     //onCanRefreshChanged.current = [];

    //     console.log('location changed. onRefresh has been reset');
    // }, [location]);

    // useEffect(() => {
    //     console.log('onRefreshCallback changed', onRefresh.current);
    // }, [onRefresh.current])

    // useEffect(() => {
    //     console.log('onCanRefreshChanged changed', onCanRefreshChanged.current);
    // }, [onCanRefreshChanged.current])    

    const setOnRefresh = (callback: RefreshCallbackType, remove?: boolean) => {
        if (!callback) return;

        if (remove){
            const idx = onRefresh.current.findIndex(x => x === callback);
            if (idx !== -1) {
                onRefresh.current.splice(idx, 1);
                onCanRefreshChanged.current.forEach(c => c?.(onRefresh.current.length > 0));
            }
        } else {
            const curCallback = onRefresh.current.find(x => x === callback);
            if (!curCallback) {
                onRefresh.current.push(callback);
                onCanRefreshChanged.current.forEach(c => c?.(onRefresh.current.length > 0));
            }
        }

        //console.log('setOnRefresh', onRefresh.current);
    }

    const setOnCanRefreshChanged = (callback: CanRefreshCallbackType, remove?: boolean) => {
        if (!callback) return;

        if (remove){
            const idx = onCanRefreshChanged.current.findIndex(x => x === callback);
            if (idx !== -1) {
                onCanRefreshChanged.current.splice(idx, 1);
                onCanRefreshChanged.current = onCanRefreshChanged.current.splice(idx, 1);
            }
        } else {
            const curCallback = onCanRefreshChanged.current.find(x => x === callback);
            
            if (!curCallback) {
                onCanRefreshChanged.current.push(callback);
            }
        }

        //console.log('setOnCanRefreshChanged', onCanRefreshChanged.current);
    }

    const handleRefresh = () => onRefresh.current.forEach(c => c?.());

    return <RefreshContext.Provider value={{
        refreshContent: handleRefresh,
        hasContentToRefresh: !!onRefresh.current.length,
        onHasContentToRefreshChanged: setOnCanRefreshChanged,
        onContentRefresh: setOnRefresh }} >
            {children}
        </RefreshContext.Provider>
}