import { useEffect } from 'react'; 
import ThemeProvider from './theme/ThemeProvider';
import { useRoutes } from 'react-router';
import {nonAuthRoutes, routes} from './router';
import { SnackbarProvider } from 'notistack';
import { useAppDispatch } from './hooks';
import { setAuthInfo } from './reducers/authSlice';
import { getUtcNow } from './utils/dateUtils';
import { restoreGridOptions } from 'reducers/pageStateSlice';
import SnackbarCloseButton from 'components/SnackbarCloseButton';
import { RefreshProvider } from 'contexts/RefreshContext';

export default function App() {
  const authInfoData = localStorage.getItem("authInfo");
  const authInfo = authInfoData ? JSON.parse(authInfoData) : {} as IAuthInfo;
  const content = useRoutes(authInfo.token ? routes : nonAuthRoutes);
  const dispath = useAppDispatch();

  useEffect(() => {
    const expireAt = new Date(Date.parse(authInfo.expireAt));
    if (authInfo && getUtcNow() < expireAt) {
      dispath(setAuthInfo(authInfo));
    }
    dispath(restoreGridOptions());
  }, []);

  return (
    <ThemeProvider>
      <RefreshProvider>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
          {content}
        </SnackbarProvider>
      </RefreshProvider>
    </ThemeProvider>
  );
}
